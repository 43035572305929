
import { defineComponent } from 'vue'
import { capitalizeFirstLetter } from '@/services/string'

export default defineComponent({
  props: {
    title: {
      type: String,
    },
  },
  setup() {
    return {
      capitalizeFirstLetter,
    }
  },
})
