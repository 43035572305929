import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f8e5ae4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-no-results-state" }
const _hoisted_2 = { class: "tm-no-results-state__body" }
const _hoisted_3 = { class: "tm-no-results-state__title" }
const _hoisted_4 = { class: "tm-no-results-state__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_icon, {
      class: "tm-no-results-state__icon",
      "custom-size": "72px",
      name: "tmi-search-off"
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, " No " + _toDisplayString(_ctx.itemsName) + " match the current filters ", 1),
      _createElementVNode("div", _hoisted_4, " Please refine your filters or wait until any " + _toDisplayString(_ctx.itemsName) + " match the selected filters. ", 1)
    ])
  ]))
}