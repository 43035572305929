
import { computed, defineComponent, watch } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  name: 'TmTablePagination',
  components: { TmButton },
  props: {
    modelValue: {
      type: Number,
      default: 1,
    },
    itemsLength: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    itemsName: {
      type: String,
    },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const { isSmMax } = useBreakpoints()
    const isLastPage = computed(() => {
      if (!props.itemsPerPage) return true
      return props.modelValue * props.itemsPerPage >= props.itemsLength
    })
    const pageStart = computed(() => {
      return (props.modelValue - 1) * props.itemsPerPage + 1
    })
    const pageStop = computed(() => {
      if (isLastPage.value || !props.itemsPerPage) return props.itemsLength
      return props.modelValue * props.itemsPerPage
    })
    const onInput = (val: number) => {
      context.emit('update:modelValue', val)
    }
    const onNext = () => {
      onInput(props.modelValue + 1)
    }
    const onPrev = () => {
      onInput(props.modelValue - 1)
    }

    watch(
      () => props.itemsPerPage,
      () => {
        onInput(1)
      }
    )

    return {
      isSmMax,
      onNext,
      onPrev,
      isLastPage,
      pageStart,
      pageStop,
    }
  },
})
