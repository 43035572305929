import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-center w100pr justify-between" }
const _hoisted_2 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_skeleton = _resolveComponent("tm-skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_skeleton, {
      type: "QChip",
      width: "128px",
      height: "20px"
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_tm_skeleton, {
        type: "QChip",
        width: "91px",
        height: "20px"
      }),
      _createVNode(_component_tm_skeleton, {
        type: "QChip",
        width: "75px",
        height: "20px",
        class: "ml-2"
      })
    ])
  ]))
}