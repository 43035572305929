
import { computed, defineComponent } from 'vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  name: 'TmTablePerPage',
  props: {
    modelValue: {
      type: Number,
      default: 10,
    },
    itemsPerPageOptions: {
      type: Array,
      default: () => [10, 25, 50, 100, 250],
    },
    type: {
      type: String,
      default: 'rows',
    },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const { isSmMax } = useBreakpoints()
    const onPerPageChange = (val: { value: number; label: number }) => {
      context.emit('update:modelValue', val.value)
    }
    const perPageOptionsParsed = computed(() => {
      return props.itemsPerPageOptions.map((num) => ({
        text: !num ? 'All' : num,
        value: num,
      }))
    })

    return {
      isSmMax,
      onPerPageChange,
      perPageOptionsParsed,
    }
  },
})
