
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TmTableCounter',
  props: {
    selectedLength: {
      type: Number,
      required: true,
    },
    totalLength: {
      type: Number,
      required: true,
    },
    itemsName: {
      type: String,
    },
  },
  emits: ['select-all', 'clear-selection'],
})
