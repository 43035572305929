
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TmNoResultsState',
  props: {
    itemsName: {
      type: String,
    },
  },
  setup() {
    return {}
  },
})
