
import type { PropType } from 'vue'
import {
  computed,
  defineComponent,
  defineAsyncComponent,
  reactive,
  watch,
  ref,
  onMounted,
  onBeforeUnmount
} from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTableCounter from '@/components/shared/table/TmTableCounter.vue'
import TmTablePagination from '@/components/shared/table/TmTablePagination.vue'
import TmTablePerPage from '@/components/shared/table/TmTablePerPage.vue'
import TmTableFooterLoading from '@/components/shared/table/TmTableFooterLoading.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import TmNoResultsState from '@/components/shared/states/TmNoResultsState.vue'
import TmSkeleton from '@/components/shared/TmSkeleton.vue'
import { useModes } from '@/compositions/modes'
import TmTableGroupTitle from '@/components/shared/table/TmTableGroupTitle.vue'

const ExpandTd = defineAsyncComponent(() => import('@/components/shared/templates/ExpandTd.vue'))

type QuasarPagination = {
  page: number;
  rowsPerPage: number;
  sortBy: string | null;
  descending: boolean
}
const alignMap: any = {
  start: 'left',
  end: 'right',
  center: 'center',
}
const createWidthStyle = (width: number | string, minWidth: number | string = 0) => {
  const widthVal = typeof width === 'number' ? `${width}px` : width
  const minWidthVal = minWidth ? typeof minWidth === 'number' ? `${minWidth}px` : minWidth : widthVal
  return `width: ${widthVal}; min-width: ${minWidthVal};`
}
const getWidthByName = (name: string) => {
  // add fixed width for standard columns
  const dateWidth = 130
  const personWidth = 180
  const circleWidth = 40
  switch (name) {
    case 'checked': return createWidthStyle(28)
    case 'avatar': return createWidthStyle(circleWidth)
    case 'type': return createWidthStyle(150)
    case 'priority': return createWidthStyle(85)
    case 'status': return createWidthStyle(130)
    case 'assignee': return createWidthStyle(personWidth)
    case 'requester': return createWidthStyle(personWidth)
    case 'to': return createWidthStyle(personWidth)
    case 'agent': return createWidthStyle(personWidth)
    case 'date': return createWidthStyle(dateWidth)
    case 'dueDate': return createWidthStyle(dateWidth)
    case 'lastUpdated': return createWidthStyle(dateWidth)
    case 'dateCreated': return createWidthStyle(dateWidth)
    case 'dateClosed': return createWidthStyle(dateWidth)
    case 'dateUnsubscribed': return createWidthStyle(dateWidth)
    case 'sendDate': return createWidthStyle(dateWidth)
    case 'dateReceived': return createWidthStyle(dateWidth)
    case 'callDate': return createWidthStyle(dateWidth)
    case 'chatDate': return createWidthStyle(dateWidth)
    case 'lastActive': return createWidthStyle(dateWidth)
    case 'activationTime': return createWidthStyle(dateWidth + 10)
    case 'actions': return createWidthStyle(52)
    case 'occurrence': return createWidthStyle(130)
  }
  return ''
}

export default defineComponent({
  name: 'TmTable',
  components: {
    TmTableGroupTitle,
    TmNoResultsState,
    TmTablePerPage,
    TmTableFooterLoading,
    TmSkeleton,
    TmTablePagination,
    TmTableCounter,
    TmButton,
    ExpandTd,
  },
  props: {
    modelValue: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    headers: {
      type: Array as PropType<TableHeaders[]>,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    showSelection: {
      type: Boolean,
      default: false,
    },
    singleSelection: {
      type: Boolean,
      default: false,
    },
    hideCounter: {
      type: Boolean,
      default: false,
    },
    itemKey: {
      type: String,
      default: 'id',
    },
    highRow: {
      type: Boolean,
      default: false,
    },
    itemsName: {
      type: String,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    hidePhantomCol: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    expand: {
      type: Boolean,
    },
    // Pagination
    hideFooter: {
      type: Boolean,
      default: false,
    },
    hidePerPage: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    itemsPerPageOptions: {
      type: Array,
      default: () => [10, 25, 50, 100, 250],
    },
    paginationLink: {
      type: String,
    },
    infiniteTable: {
      type: Boolean,
      default: false,
    },
    alignTop: {
      type: Boolean,
    },
    isLoading: {
      type: Boolean,
    },
    grouping: {
      type: Function,
    },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const { isLoadingMode } = useModes()
    const tableScrollClass = '.q-table__middle'
    let resizeObserver: ResizeObserver
    const tableRef = ref()
    const isScrollXReached = ref(false)

    const onScroll = () => {
      if (tableRef.value?.querySelector(tableScrollClass)) {
        const el = tableRef.value.querySelector(tableScrollClass)
        const { width } = el.getBoundingClientRect()
        const scrollWidth = el.scrollWidth - width
        const currentScroll = el.scrollLeft

        isScrollXReached.value = scrollWidth <= currentScroll
      }
    }

    onMounted(() => {
      const scrollEl = tableRef.value.querySelector(tableScrollClass)
      if (!scrollEl) { return }

      resizeObserver = new ResizeObserver(onScroll)
      resizeObserver.observe(scrollEl.querySelector('.q-table'))
      resizeObserver.observe(scrollEl)

      scrollEl.addEventListener('scroll', onScroll)
      scrollEl.dispatchEvent(new CustomEvent('scroll'))
    })

    window.onload = () => onScroll()

    onBeforeUnmount(() => {
      const scrollEl = tableRef.value.querySelector(tableScrollClass)

      if (!scrollEl || !resizeObserver) { return }

      scrollEl.removeEventListener('scroll', onScroll)
      resizeObserver.disconnect()
    })
    const getWidthStyle = (item: any) => {
      if (item.width || item.minWidth) {
        return createWidthStyle(item.width, item.minWidth)
      }
      return getWidthByName(item.value)
    }
    const computedHeaders = computed(() => {
      const headers = [...props.headers]
      if (!props.hidePhantomCol) {
        headers.push({ text: '', value: 'empty-spacer', hideSortable: true, class: 'pa-0', cellClass: 'pa-0' })
      }
      if (!props.hideActions) {
        headers.push({ text: '', value: 'actions', hideSortable: true, class: 'tm-table__actions', cellClass: 'tm-table__actions', width: 40 })
      }
      return headers
        .filter((item: any) => !item.hidden)
        .map((item: any) => ({
          ...item,
          name: item.value,
          label: item.text,
          field: item.field || item.value,
          headerClasses: item.class,
          classes: item.cellClass,
          align: alignMap[item.align] || alignMap.start,
          headerStyle: getWidthStyle(item),
          sortable: !item.hideSortable,
        }))
    })
    const colspan = computed(() => {
      return props.showSelection ? computedHeaders.value.length + 1 : computedHeaders.value.length
    })
    const onInput = (val: any) => {
      context.emit('update:modelValue', val)
    }
    const selectAll = () => {
      onInput(props.items)
    }
    const clearSelection = () => {
      onInput([])
    }

    const radioValue = computed(() => {
      if (!props.showSelection || !props.singleSelection || !props.modelValue!.length) {
        return null
      }
      return props.modelValue[0][props.itemKey]
    })
    const onRadioUpdate = (id: any) => {
      onInput((props.items as Array<{ [key: string]: any }>).filter((item) => item[props.itemKey] === id))
    }

    const page = ref(1)

    // Pagination
    const pagination = reactive<QuasarPagination>({
      page: 1,
      rowsPerPage: props.infiniteTable ? props.itemsPerPage * page.value : props.itemsPerPage,
      sortBy: null,
      descending: false,
    })

    const setPagination = (val: QuasarPagination) => {
      pagination.page = val.page
      pagination.rowsPerPage = val.rowsPerPage
      pagination.sortBy = val.sortBy
      pagination.descending = val.descending
    }

    watch(() => props.itemsPerPage, (val) => {
      pagination.rowsPerPage = val
    })

    const moreClick = () => {
      page.value++
      pagination.rowsPerPage = props.itemsPerPage * page.value
    }

    const computedItems = computed(() => {
      if (!props.grouping) { return props.items }

      const newItems: any[] = []
      const paginationStartAt = (pagination.page - 1) * pagination.rowsPerPage
      const paginationEndAt = (pagination.page - 1) * pagination.rowsPerPage + pagination.rowsPerPage

      const paginatedGrouppedItems = props.items
        .slice(paginationStartAt, paginationEndAt).map((e: any) => ({
          ...e,
          groupTitle: '',
        }))
        .sort((e: any) => {
          const statusName = e.statusTicket.name.toLowerCase()
          if (statusName === 'new') {
            return -2
          }
          return statusName === 'open' ? -1 : 1
        })

      const grouppedItems = paginatedGrouppedItems
        .map(props.grouping as () => any)
        .reduce((acc, val, i) => {
          acc[val] = (acc[val] || []).concat(paginatedGrouppedItems[i])
          return acc
        }, {})

      Object.keys(grouppedItems).forEach((e: string) => {
        const localItems = grouppedItems[e]
        localItems[0].groupTitle = e

        newItems.push(...localItems)
      })

      return [
        ...props.items.slice(0, paginationStartAt),
        ...newItems,
        ...props.items.slice(paginationEndAt),
      ]
    })

    return {
      computedItems,
      computedHeaders,
      colspan,
      tableRef,
      onInput,
      selectAll,
      clearSelection,
      isScrollXReached,
      radioValue,
      onRadioUpdate,
      // Pagination
      pagination,
      setPagination,
      page,
      moreClick,
      isLoadingMode,
    }
  },
})
