import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ba952ab"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-table-per-page" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "font-weight-semi-bold emphasize--text" }
const _hoisted_4 = { class: "font-weight-semi-bold emphasize--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isSmMax)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Show on page"))
      : _createCommentVNode("", true),
    _createVNode(_component_tm_field, {
      "model-value": _ctx.modelValue,
      options: _ctx.perPageOptionsParsed,
      type: "select",
      "option-value": "value",
      outlined: "",
      dense: "",
      clearable: false,
      size: "small",
      class: "sm-font",
      "onUpdate:modelValue": _ctx.onPerPageChange
    }, {
      "selected-item": _withCtx(({ opt }) => [
        _createElementVNode("span", _hoisted_3, _toDisplayString(opt) + " rows ", 1)
      ]),
      "selected-item-truncate": _withCtx(({ opt }) => [
        _createElementVNode("span", _hoisted_4, _toDisplayString(!opt ? 'All' : opt) + " " + _toDisplayString(_ctx.type), 1)
      ]),
      _: 1
    }, 8, ["model-value", "options", "onUpdate:modelValue"])
  ]))
}